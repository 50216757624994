@import "../../styles/variables";
@import "../../styles/breakpoints";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  text-decoration: none;
  position: absolute;
  height: 85px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 100;
  color: $Magnolia;
  &__navBar {
    width: 90vw;
    margin: 0 auto;
    max-width: $desktop_md;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.navBar__logo {
  width: $menu_item;
}
