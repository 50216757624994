@import "../../styles/variables";
@import "../../styles/breakpoints";

.card {
  background-color: $BlackTurtle;
  color: $Night-20;
  border: 1px solid $Night-80;
  width: 95%;
  height: fit-content;
  border-radius: 2rem;
  margin: 1.5rem auto;
  padding: 2rem;
  padding-bottom: 0;
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  text-align: left;

  &__imgFrame {
    width: 20rem;
    flex-grow: 1;
    height: 16rem;
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__data {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20rem;
    height: 14rem;
  }

  &__title {
    color: $Magnolia;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }
  &__tags {
    display: flex;
    list-style: none;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  &__description {
    flex-grow: 1;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  &__links {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}

@media (max-width: $mobile) {
  .card {
    width: 90%;
    border-radius: 1.5rem;
    margin: 0.75rem;
    padding: 1.5rem;
    flex-wrap: wrap;
    gap: 1rem;
    &__imgFrame {
      border-radius: 1rem;
    }
    &__title {
      margin: 1rem 0;
    }
    &__description {
      font-size: 0.9rem;
      overflow: hidden;
    }
  }
}
