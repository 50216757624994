@import "../../styles/variables";
@import "../../styles/breakpoints";

.container {
  width: 100vw;
  max-width: 900px;
  height: fit-content;
  margin: 2.5rem auto;
  padding: 3rem 0;
  color: $Magnolia;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow-x: hidden;
}

.title {
  margin: 0 auto 5rem auto;
  font-size: clamp(2.25rem, 5vw, 3rem);
  font-weight: 400;
  &__dot {
    color: $Lime;
    font-size: 2rem;
  }
  &__highlight {
    font-weight: 800;
  }
}
.projectsGallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;

  @media (max-width: $mobile) {
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100vw;
    padding-bottom: 2rem;
    overflow-x: scroll;
  }
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background-color: $Night-80;
    border-radius: 1rem;
    margin: 7rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: $Night-40;
  }
}
