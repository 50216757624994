@import "../../styles/variables";
@import "../../styles/breakpoints";
footer {
  padding: 0.5rem 1rem;
  margin: auto;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: $Night;
  border-radius: 0.3rem;
  text-align: center;
  color: $Magnolia;
}

footer a {
  color: $Lime;
}
