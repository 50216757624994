@import "../../styles/variables";
@import "../../styles/breakpoints";
@import "../../styles/animations";

.container {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: clip;
  background: radial-gradient(circle at 100vw 0, rgba(192, 255, 0, 0.05) 0%, transparent 35%);

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    &::after {
      content: url(../../assets/logo.svg);
      width: 100%;
      max-width: 45rem;
      margin: auto;
      scale: 1.3;
      position: absolute;
      left: 0;
      right: 0;
      opacity: 0.2;
      animation: rotativeLogo 240s linear infinite;
      z-index: -1;
    }
  }

  &__title {
    font-size: clamp(2rem, 10vw, 4rem);
    margin: 0.25rem auto;
    font-weight: 900;
    color: $Magnolia;
  }
  &__text {
    font-weight: 300;
    color: $Magnolia;
    font-size: clamp(1rem, 5vw, 2.25rem);
  }
}
.text__highlight {
  color: $Lime;
  font-size: clamp(2rem, 3rem, 5rem);
}
.bold {
  font-weight: 600;
}
.skipper__arrow {
  cursor: pointer;
  position: absolute;
  bottom: 10%;
  left: 35%;
  right: 35%;
  margin: auto;
  text-align: center;
  fill: $Lime;
  width: 2rem;
  height: 2rem;
  animation: float ease-in-out 2s infinite alternate;
}
