@import "../../styles/variables";
@import "../../styles/breakpoints";

.container {
  width: 100vw;
  height: fit-content;
  padding: 3rem 1rem;
  color: $Magnolia;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content {
  max-width: 900px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
}

.title {
  text-align: center;
  margin: 0 auto 5rem auto;
  font-size: clamp(2.25rem, 5vw, 3rem);
  font-weight: 400;
  &__dot {
    color: $Lime;
    font-size: 2rem;
  }
  &__highlight {
    font-weight: 800;
  }

  @media (max-width: $mobile) {
    margin: auto;
    text-align: left;
    width: 12rem;
    position: relative;
    top: 5rem;
    left: 5rem;
    z-index: 2;
  }
}

.photo {
  width: clamp(200px, 50%, 500px);
  @media (max-width: $mobile) {
    position: relative;
    top: -3.5rem;
    left: -5.5rem;
    margin-left: 1.5rem;
    z-index: 1;
  }
}

.textBox {
  @media (max-width: $mobile) {
    position: relative;
    top: -6rem;
    margin-bottom: -5rem;
    text-align: center;
  }
  width: clamp(20rem, 50%, 600px);
  height: fit-content;
  padding: 2.5rem 1.5rem;
  margin: auto;
  border-radius: 0.5rem;
  &__social {
    display: flex;
    gap: 1rem;
    width: fit-content;
    margin-bottom: 1rem;
    @media (max-width: $mobile) {
      position: relative;
      margin: 0;
      top: -3rem;
      left: 60%;
      z-index: 1;
    }
    & a {
      color: $Night-60;
      & .linkIcon {
        width: 1.8rem;
        height: 100%;
      }
      & :hover {
        color: $Magnolia;
        transition: 300ms;
      }
    }
  }
  &__text {
    margin: auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
}

.resumeBtn {
  color: $Magnolia;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: fit-content;
  border: solid 1px $Lime;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin: 1.5rem 0;
  background-color: $Night;
  transition: 350ms;
  @media (max-width: $mobile) {
    margin: 1.5rem auto;
  }
  &:hover {
    scale: (1.1);
  }
}

.text {
  text-align: center;
  margin: 3.5rem auto;
  font-size: 1.3rem;
}

.techList {
  width: 70vw;
  max-width: 30rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin: 0 auto;
  &__item {
    width: 3rem;
    height: 3rem;
    @media (max-width: $mobile) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
