@keyframes rotativeLogo {
  to {
    rotate: 360deg;
  }
}

@keyframes float {
  to {
    transform: translateY(1rem);
  }
}

@keyframes floatAnimation {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(75vw, 200vh);
  }

  100% {
    transform: translate(0, 0);
  }
}
