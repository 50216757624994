@import "../../styles/variables";
@import "../../styles/animations";
@import "../../styles/breakpoints";

.container {
  height: 100vh;
  width: 100%;
  overflow-x: clip;
  position: relative;
  text-align: center;
  color: $Magnolia;

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: clamp(1rem, 5vw, 1.75rem);
    &::after {
      content: url(../../assets/world_map.svg);
      width: 100%;
      scale: (1.5);
      position: absolute;
      opacity: 0.7;

      @media (max-width: $mobile) {
        transform: translateX(-40%) scale(0.75);
      }
      z-index: -1;
    }
  }
}

.title {
  font-size: clamp(2.25rem, 5vw, 3rem);
  font-weight: 400;

  &__highlight {
    font-weight: 800;
  }
  &__dot {
    color: $Lime;
  }
}

.email {
  margin: 5rem auto;
  line-height: normal;
  & a {
    color: $Magnolia;
  }
}
.list {
  &__links {
    display: flex;
    margin: 1rem auto;
    gap: 1rem;
  }
  &__icon {
    color: $Night-40;
    width: clamp(1.5rem, 5vw, 2rem);
    height: 100%;
    transition: 300ms;
    & :hover {
      color: $Magnolia;
    }
  }
}
