$Lime: #baff00;
$Lime-60: #e0ff66;

$SmokyBlack: #0e1108;
$Background-Dark: #1e1e1e;
$BlackTurtle: #202020;
$Night: #141414;
$Night-80: #434343;
$Night-60: #727272;
$Night-40: #a1a1a1;
$Night-20: #d0d0d0;

$Magnolia: #f7f0f5;

$menu_item: 50px;
